(function (a, b, c, d) {
  a = 'https://static.zdassets.com/ekr/snippet.js?key=9c436d89-e093-4eed-aff1-f5b1a28ed0b7';
  b = document;
  c = 'script';
  d = b.createElement(c);
  d.src = a;
  d.id = 'ze-snippet';
  d.type = 'text/java' + c;
  d.async = true;
  d.defer = true;
  a = b.getElementsByTagName(c)[0];
  a.parentNode.insertBefore(d, a);

  function injectStyles() {
    const iframe = document.getElementById('launcher');
    if (!iframe) {
      requestAnimationFrame(injectStyles);
      return;
    }
    iframe.style.height = '48px';
    iframe.style.width = '48px';

    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
    if (!iframeDoc.body) {
      requestAnimationFrame(injectStyles);
      return;
    }

    const style = iframeDoc.createElement('style');
    style.textContent = `        
        div[shape="circle"] {
          width: 48px !important;
          height: 48px !important;
        }
      `;

    iframeDoc.head.appendChild(style);
  }

  window.addEventListener('load', function () {
    requestAnimationFrame(injectStyles);
  });
})();
